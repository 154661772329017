import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Stack from 'react-bootstrap/Stack';
import PropTypes from 'prop-types';

import Select from './select';
import {selectOptions} from '../utils/select-options';
import TripUpdatesTable from './trip-updates-table';
import Input from './input';
import config from '../config';

export default function TripUpdatesPage(){
    /*store and initialise data in function component state*/
    const [oset, setOset] = useState(1);
    const [limit, setLimit] = useState(parseInt(selectOptions[0],10));
    const [searchField, setSearchField] = useState('');
    const handleClickPrev = () => {
        setOset((oset) => (oset > 1 ? --oset : oset));
    };
    const handleClickNext = () => {
        setOset((oset) => ++oset);
    };
    const handleChangeLimit = (event) => {
        setLimit((limit) => parseInt(event.target.value,10));
    };
    const handleSearch = (e) => {
        setSearchField((searchField)=>e.target.value);
    };
    return (
        <>
            <Stack direction="horizontal" gap={1} className="m-1">
                <Button variant="secondary" onClick={handleClickPrev}>
		    prev
                </Button>
                <Button variant="secondary" onClick={handleClickNext}>
		    next
                </Button>
		    <Select
			defaultValue={selectOptions[0]}
			id="tablePageLimit"
			name="tablePageLimit"
			onChange={handleChangeLimit}
			options={selectOptions}
		    />
		    <Input
			id="tablePageSearch"
			name="tablePageSearch"
			onChange={handleSearch}
			placeholder="Search table globally"
			type="search"
			title="Enter search value"
			value={searchField}
		    />
            </Stack>
            <TripUpdatesTable
		isFetched={false}
		oset={oset}
		limit={limit}
		filter={searchField}
	    />
        </>
    );
};
