import React, { useEffect, useState } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import Table from 'react-bootstrap/Table';
import Alert from 'react-bootstrap/Alert';
import Badge from 'react-bootstrap/Badge';

import {readPbf} from '../utils/gtfs-rt-utils';
import TripUpdatesTableHead from './trip-updates-table-head';
import TripUpdatesTableEntries from './trip-updates-table-entries';
import config from '../config';
import {filterData} from '../utils/filter-data';

/*the simplest way to define a component is to write a JavaScript function*/
/*destructure props object*/
export default function TripUpdatesTable ({isFetched, oset, limit, filter}) {
    //////console.log('TripUpdatesTable: oset: ' + oset);
    ////////console.log('TripUpdatesTable: limit: ' + limit);

    const [ary, setAry] = useState([]);
    const [aryFiltered, setAryFiltered] = useState([]);
    const [fetchCompleted, setFetchCompleted] = useState(isFetched);
    const [entityCount, setEntityCount] = useState(0);
    const [entityTripUpdateCount, setEntityTripUpdateCount] = useState(0);
    /*fetch ary in a JavaScript function*/
    const fetch = async () => {
        try {
            /*TODO handle errors: https://www.valentinog.com/blog/await-react/*/
	    //
	    const optns = { responseType: 'arraybuffer'	};
            const address = `${config.API}gtfs-rt-file`;
            const res = await axios.get(address, optns);

            if(res.data){
                //////console.log('fetch() res available');
		const rry = readPbf(res.data);
		const rryLngth= rry.length;
                //console.log('fetch() rryLngh: ' + rryLngth);
		setEntityCount((entityCount) => rryLngth);

		const rryETU = new Array();
		rry.forEach(entity => {
		    const entityTripUpdate = entity.trip_update;
		    if (entityTripUpdate) {
			rryETU.push(entityTripUpdate);
		    }
		});
		const rryETULngth= rryETU.length;
                //console.log('fetch() rryETULength: ' + rryETULngth);
		setEntityTripUpdateCount((entityTripUpdateCount) => rryETULngth);

		const rryOstLmt = new Array();
		let j = 0
		for(let i = (oset - 1) * limit; i < rryETULngth ; i++){
		    if(j < limit){
			rryOstLmt.push(rryETU[i]);
			j++;
		    }
		}
                //console.log('fetch() rryOstLmt.length: ' + rryOstLmt.length);
		setAry((ary) => rryOstLmt);

		let data=filterData(rryOstLmt,'trip_updates',filter);
		setAryFiltered((aryFiltered) => data);
	    }else{
                console.error('fetch() res NOT available');
	    }
        } catch (err) {
            console.error('err.message: ' + err.message);
	    setAry((ary) => []);
	    setAryFiltered((aryFiltered) => []);
        }
    };
    useEffect(()=>{
	setAryFiltered((aryFiltered)=>{
	    let filtered=filterData(ary,'trip_updates',filter);
	    return filtered;
	});
    },[filter]);
    useEffect(() => {
	/*effect goes here*/
	fetch();
	setFetchCompleted((fetchCompleted)=>true);
	//console.log('entityCount: ' + entityCount);
	//console.log('entityTripUpdateCount: ' + entityTripUpdateCount);
	/*use an empty dependency array to ensure the hook is running only once*/
	/*TODO study dependency array: https://reactjs.org/docs/hooks-effect.html*/
    }, [oset,limit]);
    if(fetchCompleted && aryFiltered.length > -1){
	/*return a React element*/
	return (
            <>
		<Badge bg="secondary">
		    abs entity count: {entityCount}&nbsp;
		</Badge>
		&nbsp;
		<Badge bg="secondary">
		    abs TripUpdate count: {entityTripUpdateCount}&nbsp;
		</Badge>
		&nbsp;
		<Badge bg="secondary">
		    page trip count: {ary.length}&nbsp;
		</Badge>
		&nbsp;
		<Badge bg="secondary">
		    filtered trip count: {aryFiltered.length}
		</Badge>
		&nbsp;
		<Table
		    striped
		    bordered
		    hover
		    size="sm"
		    variant="dark"
		    responsive
		>
                    <thead>
			<TripUpdatesTableHead />
		    </thead>
                    <tbody>
			<TripUpdatesTableEntries aryData={aryFiltered} />
		    </tbody>
		</Table>
            </>
	);
    }else{
	return (
	    <Alert variant={'secondary'} onClose={() => setShow(false)} dismissible>
		<Badge bg="secondary">TripUpdate</Badge> entities loading...
            </Alert>

	);
    }
}
TripUpdatesTable.propTypes = {
    isFetched: PropTypes.bool,
    offset: PropTypes.number,
    limit: PropTypes.number,
    filter: PropTypes.string
};
