import React from 'react';

const TripUpdatesTableHead = () => {
    return (
        <tr>
	    <th>Trip:trip_id</th>
	    <th>Trip:route_id</th>
	    <th>Trip:direction_id</th>
	    <th>Trip:start_time</th>
	    <th>Trip:start_date</th>
	    <th>timestamp</th>
	    <th>delay</th>
        </tr>
    );
};

export default TripUpdatesTableHead;
