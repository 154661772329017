import React from 'react';
import PropTypes from 'prop-types';
import TripUpdatesEntry from './trip-updates-table-entry';

export default function TripUpdatesTableEntries ({aryData}) {
    if (aryData.length > 0) {
        //iterate over array
        return aryData.map((item, index) => {
	    if (item.trip) {
		//console.log('TripUpdatesTableEntries: trip available');
		const trip = item.trip;
		
		return (
		    <TripUpdatesEntry
		    tripId={typeof trip.trip_id  !== 'undefined' ? trip.trip_id : null}
		    routeId={typeof trip.route_id  !== 'undefined' ? trip.route_id : null}
		    directionId={typeof trip.direction_id  !== 'undefined' ? trip.direction_id : null}
		    startTime={typeof trip.start_time  !== 'undefined' ? trip.start_time : null}
		    startDate={typeof trip.start_date  !== 'undefined' ? trip.start_date : null}
		    timestamp={typeof item.timestamp  !== 'undefined' ? item.timestamp : null}
		    delay={typeof item.delay  !== 'undefined' ? item.delay : null}
                    key={index}
		/>
		);
	    } else {
		console.log('ERROR: TripUpdatesTableEntries: REQUIRED trip NOT available');
	    }
        });
    }else{
	//data is empty
	return null;
    }
}
TripUpdatesTableEntries.propTypes = {
    aryData: PropTypes.array
};
