import React from 'react';
import packageInfo from '../../package.json'
const VERSION = packageInfo.version;
const Contact = () => {
    return (
        <>
            <p>
		For questions about this website please do not hesitate to reach out to dialog
		(at) swingbe (dot) de.
            </p>
            <p>
		Source code is controlled and provided using{' '}
                <a
                    href="https://git.wtf-eg.de/dancesWithCycles/gtfs-rt-display"
                    target="_blank"
                >
		    Git
                </a>
		.
            </p>
	    <p>
                <a
                    href="https://www.swingbe.de/imprint"
                    target="_blank"
                >
		    Imprint
                </a>
	    </p>
	    <p>
                <a
                    href="https://www.swingbe.de/privacy-policy"
                    target="_blank"
                >
		    Privacy Policy
                </a>
	    </p>
	    <p>
		Version: {VERSION}
	    </p>
        </>
    );
};
export default Contact;
