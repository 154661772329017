function filterData(data, name,filter){
    if(data.length>0){
	//console.log('filterData() data.length: '+data.length);
	//console.log('filterData() name: '+name);
	//console.log('filterData() filter:'+filter);
	switch(name){
	case 'fare_zones_history':
	    //TODO implement
	    console.log('filterData() //TODO implement fare_zones_history');
	    return data;
	    break;
	case 'tdb_fare_zones':
	    return data.filter((item, index) => {
		return (
		    item.id.toLowerCase().includes(filter.toLowerCase()) ||
			(item.external!==null && item.external.toLowerCase().includes(filter.toLowerCase())) ||
			(item.internal!==null && item.internal.toLowerCase().includes(filter.toLowerCase())) ||
			(item.name!==null && item.name.toLowerCase().includes(filter.toLowerCase())) ||
			(item.short_name!==null && item.short_name.toLowerCase().includes(filter.toLowerCase())) ||
			(item.type!==null && item.type.toLowerCase().includes(filter.toLowerCase())) ||
			(item.valid_from!==null && item.valid_from.toLowerCase().includes(filter.toLowerCase())) ||
			 (item.valid_until!==null && item.valid_until.toLowerCase().includes(filter.toLowerCase()))
		);
	    });
	    break;
	case 'lct_msg':
	    return data.filter((item, index) => {
		return (
		    item.bs_id.toLowerCase().includes(filter.toLowerCase()) ||
			(item.vc_trip!==null && item.vc_trip.toLowerCase().includes(filter.toLowerCase())) ||
			(item.vc_route!==null && item.vc_route.toLowerCase().includes(filter.toLowerCase())) ||
			(item.vc_tenant!==null && item.vc_tenant.toLowerCase().includes(filter.toLowerCase())) ||
			(item.vc_date!==null && item.vc_date.toLowerCase().includes(filter.toLowerCase())) ||
			(item.vc_time!==null && item.vc_time.toLowerCase().includes(filter.toLowerCase())) ||
			(item.vc_lon!==null && item.vc_lon.toLowerCase().includes(filter.toLowerCase())) ||
			(item.vc_lat!==null && item.vc_lat.toLowerCase().includes(filter.toLowerCase()))
		);
	    });
	    break;
	case 'localization':
	    return data.filter((item, index) => {
		return (
		    item.localization_id.toLowerCase().includes(filter.toLowerCase()) ||
			(item.name!==null && item.name.toLowerCase().includes(filter.toLowerCase())) ||
			(item.lang_de!==null && item.lang_de.toLowerCase().includes(filter.toLowerCase())) ||
			(item.lang_en!==null && item.lang_en.toLowerCase().includes(filter.toLowerCase())) ||
			(item.version_id!==null && item.version_id.toLowerCase().includes(filter.toLowerCase()))
		);
	    });
	    break;
	case 'relations':
	    return data.filter((item, index) => {
		return (
		    (item.dtype!==null && item.dtype.toLowerCase().includes(filter.toLowerCase())) ||
			(item.id!==null && item.id.toLowerCase().includes(filter.toLowerCase())) ||
			(item.active!=null && item.active.toString().toLowerCase().includes(filter.toLowerCase())) ||
			(item.direct_purchase!==null && item.direct_purchase.toString().toLowerCase().includes(filter.toLowerCase())) ||
			(item.disabled!==null && item.disabled.toString().toLowerCase().includes(filter.toLowerCase())) ||
			(item.info!==null && item.info.toLowerCase().includes(filter.toLowerCase())) ||
			(item.last_modified!==null && item.last_modified.toLowerCase().includes(filter.toLowerCase())) ||
			(item.price_level!==null && item.price_level.toLowerCase().includes(filter.toLowerCase())) ||
			(item.start_zone!==null && item.start_zone.toLowerCase().includes(filter.toLowerCase())) ||
			(item.target_zone!==null && item.target_zone.toLowerCase().includes(filter.toLowerCase())) ||
			(item.valid_from!==null && item.valid_from.toLowerCase().includes(filter.toLowerCase())) ||
			(item.valid_until!==null && item.valid_until.toLowerCase().includes(filter.toLowerCase())) ||
			(item.via_name!==null && item.via_name.toLowerCase().includes(filter.toLowerCase())) ||
			(item.via_fare_zone!==null && item.via_fare_zone.toLowerCase().includes(filter.toLowerCase())) ||
			(item.zones!==null && item.zones.toLowerCase().includes(filter.toLowerCase())) ||
			(item.matching_via_id!==null && item.matching_via_id.toLowerCase().includes(filter.toLowerCase())) ||
			(item.variant!==null && item.variant.toLowerCase().includes(filter.toLowerCase())) ||
			(item.created_user_id!==null && item.created_user_id.toLowerCase().includes(filter.toLowerCase())) ||
			(item.last_modified_user_id!==null && item.last_modified_user_id.toLowerCase().includes(filter.toLowerCase())) ||
			(item.comment!== null && item.comment.toLowerCase().includes(filter.toLowerCase())) ||
			(item.reverse_direction_id!== null && item.reverse_direction_id.toLowerCase().includes(filter.toLowerCase())) ||
			(item.via_id!==null && item.via_id.toLowerCase().includes(filter.toLowerCase())) ||
			(item.all_transit_zones!=null && item.all_transit_zones.toString().toLowerCase().includes(filter.toLowerCase()))
		);
	    });
	    break;
	case 'price_levels':
	    return data.filter((item, index) => {
		return (
		    item.price_level_id.toString().toLowerCase().includes(filter.toLowerCase()) ||
			(item.short_name!==null && item.short_name.toLowerCase().includes(filter.toLowerCase())) ||
			(item.name!==null && item.name.toLowerCase().includes(filter.toLowerCase()))
		);
	    });
	    break;
	case 'price':
	    return data.filter((item, index) => {
		return (
		    item.price_id.toString().toLowerCase().includes(filter.toLowerCase()) ||
			(item.product_id!==null && item.product_id.toString().toLowerCase().includes(filter.toLowerCase())) ||
			(item.price_level_id!==null && item.price_level_id.toString().toLowerCase().includes(filter.toLowerCase())) ||
			(item.id_ticket!==null && item.id_ticket.toString().toLowerCase().includes(filter.toLowerCase())) ||
			(item.price!==null && item.price.toString().toLowerCase().includes(filter.toLowerCase())) ||
			(item.duration!==null && item.duration.toLowerCase().includes(filter.toLowerCase())) ||
			(item.priority!==null && item.priority.toString().toLowerCase().includes(filter.toLowerCase())) ||
			(item.localization_id4add_info!==null && item.localization_id4add_info.toString().toLowerCase().includes(filter.toLowerCase())) ||
			(item.localization_id4ticket_descr!==null && item.localization_id4ticket_descr.toString().toLowerCase().includes(filter.toLowerCase())) ||
			(item.localization_id4sale_text1!==null && item.localization_id4sale_text1.toString().toLowerCase().includes(filter.toLowerCase())) ||
			(item.localization_id4sale_text2!==null && item.localization_id4sale_text2.toString().toLowerCase().includes(filter.toLowerCase())) ||
			(item.localization_id4ticket_note1!==null && item.localization_id4ticket_note1.toString().toLowerCase().includes(filter.toLowerCase())) ||
			(item.localization_id4ticket_note2!==null && item.localization_id4ticket_note2.toString().toLowerCase().includes(filter.toLowerCase())) ||
			(item.localization_id4note_lang!==null && item.localization_id4note_lang.toString().toLowerCase().includes(filter.toLowerCase())) ||
			(item.filter_code!==null && item.filter_code.toString().toLowerCase().includes(filter.toLowerCase())) ||
			(item.version_id!==null && item.version_id.toString().toLowerCase().includes(filter.toLowerCase()))
		);
	    });
	    break;
	case 'product':
	    return data.filter((item, index) => {
		return (
		    item.product_id.toString().toLowerCase().includes(filter.toLowerCase()) ||
			(item.id_prod!==null && item.id_prod.toLowerCase().includes(filter.toLowerCase())) ||
			(item.ext_prod_localization_id!==null && item.ext_prod_localization_id.toLowerCase().includes(filter.toLowerCase())) ||
			(item.info_prod_localization_id!==null && item.info_prod_localization_id.toLowerCase().includes(filter.toLowerCase()))
		);
	    });
	    break;
	case 'sales_parameter':
	    return data.filter((item, index) => {
		return (
		    item.sales_parameter_id.toString().includes(filter.toLowerCase()) ||
			(item.product_id!==null && item.product_id.toString().toLowerCase().includes(filter.toLowerCase())) ||
			(item.cnt_presale_days!==null && item.cnt_presale_days.toString().toLowerCase().includes(filter.toLowerCase())) ||
			(item.app_prsnt_after_val!==null && item.app_prsnt_after_val.toString().toLowerCase().includes(filter.toLowerCase())) ||
			(item.bday!==null && item.bday.toString().toLowerCase().includes(filter.toLowerCase())) ||
			(item.num_add_names!==null && item.num_add_names.toString().toLowerCase().includes(filter.toLowerCase())) ||
			(item.val_type!==null && item.val_type.toLowerCase().includes(filter.toLowerCase())) ||
			(item.val_days!==null && item.val_days.toString().toLowerCase().includes(filter.toLowerCase())) ||
			(item.val_beg_m_f!==null && item.val_beg_m_f.toLowerCase().includes(filter.toLowerCase())) ||
			(item.val_beg_s_s!==null && item.val_beg_s_s.toLowerCase().includes(filter.toLowerCase())) ||
			(item.val_end!==null && item.val_end.toLowerCase().includes(filter.toLowerCase())) ||
			(item.version_id!==null && item.version_id.toString().toLowerCase().includes(filter.toLowerCase()))
		);
	    });
	    break;
	case 'tdb_stops':
	    return data.filter((item, index) => {
		return (
		    item.id.toLowerCase().includes(filter.toLowerCase()) ||
			(item.active!==null && item.active.toString().toLowerCase().includes(filter.toLowerCase())) ||
			(item.lon!==null && item.lon.toLowerCase().includes(filter.toLowerCase())) ||
			(item.location!==null && item.location.toLowerCase().includes(filter.toLowerCase())) ||
			(item.lat!==null && item.lat.toLowerCase().includes(filter.toLowerCase())) ||
			(item.stop_long_name!==null && item.stop_long_name.toLowerCase().includes(filter.toLowerCase())) ||
			(item.stop_name!==null && item.stop_name.toLowerCase().includes(filter.toLowerCase())) ||
			(item.stop_name_extern!==null && item.stop_name_extern.toLowerCase().includes(filter.toLowerCase())) ||
			(item.fare_zone_1!==null && item.fare_zone_1.toLowerCase().includes(filter.toLowerCase())) ||
			(item.fare_zone_2!==null && item.fare_zone_2.toLowerCase().includes(filter.toLowerCase())) ||
			(item.fare_zone_3!==null && item.fare_zone_3.toLowerCase().includes(filter.toLowerCase())) ||
			(item.fare_zone_4!==null && item.fare_zone_4.toLowerCase().includes(filter.toLowerCase())) ||
			(item.valid_from!==null && item.valid_from.toLowerCase().includes(filter.toLowerCase())) ||
			(item.valid_until!==null && item.valid_until.toLowerCase().includes(filter.toLowerCase())) ||
			(item.last_modified!==null && item.last_modified.toLowerCase().includes(filter.toLowerCase()))
		);
	    });
	    break;
	case 'ticket-selection':
	    return data.filter((item,index)=>{
		return (
		    item.price_id.toString().toLowerCase().includes(filter.toLowerCase()) ||
			(item.ext_prod_de!==null && item.ext_prod_de.toLowerCase().includes(filter.toLowerCase())) ||
			(item.id_ticket!==null && item.id_ticket.toString().toLowerCase().includes(filter.toLowerCase())) ||
			(item.price!==null && item.price.toString().toLowerCase().includes(filter.toLowerCase())) ||
			(item.name!==null && item.name.toLowerCase().includes(filter.toLowerCase())) ||
			(item.short_name!==null && item.short_name.toLowerCase().includes(filter.toLowerCase())) ||
			(item.priority!==null && item.priority.toString().toLowerCase().includes(filter.toLowerCase())) ||
			(item.duration!==null && item.duration.toLowerCase().includes(filter.toLowerCase())) ||
			(item.val_beg_m_f!==null && item.val_beg_m_f.toLowerCase().includes(filter.toLowerCase())) ||
			(item.val_beg_s_s!==null && item.val_beg_s_s.toLowerCase().includes(filter.toLowerCase())) ||
			(item.val_end!==null && item.val_end.toLowerCase().includes(filter.toLowerCase()))
		);
	    });
	    break;
	case 'trip_updates':
	    return data.filter((item, index) => {
		return (
		    (item.trip.trip_id !== null && item.trip.trip_id.toString().toLowerCase().includes(filter.toLowerCase())) ||
			(item.trip.route_id !== null && item.trip.route_id.toString().toLowerCase().includes(filter.toLowerCase())) ||
			(item.trip.direction_id !== null && item.trip.direction_id.toString().toLowerCase().includes(filter.toLowerCase())) ||
			(item.trip.start_time !== null && item.trip.start_time.toString().toLowerCase().includes(filter.toLowerCase())) ||
			(item.trip.start_date !== null && item.trip.start_date.toString().toLowerCase().includes(filter.toLowerCase())) ||
			(item.timestamp !== null && item.timestamp.toString().toLowerCase().includes(filter.toLowerCase())) ||
			(item.delay !== null && item.delay.toString().toLowerCase().includes(filter.toLowerCase()))
		);
	    });
	case 'tokens':
	    return data;
	    break;
	case 'users':
	    console.log('filterData() //TODO implement users');
	    return data;
	    break;
	case 'versions':
	    return data.filter((item, index) => {
		return (
		    item.version_id.toString().toLowerCase().includes(filter.toLowerCase()) ||
			(item.name!==null && item.name.toLowerCase().includes(filter.toLowerCase())) ||
			(item.valid_from!==null && item.valid_from.toLowerCase().includes(filter.toLowerCase())) ||
			(item.valid_until!==null && item.valid_until.toLowerCase().includes(filter.toLowerCase())) ||
			(item.descr!==null && item.descr.toLowerCase().includes(filter.toLowerCase()))
		);
	    });
	    break;
	default:
            console.error(`filterData() name: ${name} unknown`);
	    return data;
	}
    }
    return data;
};
module.exports = {
    filterData
};
