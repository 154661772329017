import React from 'react';
import PropTypes from 'prop-types';

/*destructure props object*/
const TripUpdatesTableEntry = ({
    tripId,
    routeId,
    directionId,
    startTime,
    startDate,
    timestamp,
    delay
}) => {
    return (
        <tr>
	    <td>{tripId}</td>
	    <td>{routeId}</td>
	    <td>{directionId}</td>
	    <td>{startTime}</td>
	    <td>{startDate}</td>
            <td>{timestamp}</td>
	    <td>{delay}</td>
        </tr>
    );
};

TripUpdatesTableEntry.propTypes = {
    tripId: PropTypes.string,
    routeId: PropTypes.string,
    directionId: PropTypes.number,
    startTime: PropTypes.string,
    startDate: PropTypes.string,
    timestamp: PropTypes.number,
    delay: PropTypes.number
};

export default TripUpdatesTableEntry;
